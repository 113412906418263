import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { Products_Relocations_DiscontinueProductType } from '@monorepo-types/dc';
import { RequestModels_Products_Calculator_CalculatorRequestModel } from '@monorepo-types/dc';
import { RequestModels_Products_Leads_BusinessLeadRequestModel } from '@monorepo-types/dc';
import { RequestModels_Products_Leads_CommercialOpportunityRequestModel } from '@monorepo-types/dc';
import { RequestModels_Products_Leads_CreateLeadAppointment } from '@monorepo-types/dc';
import { RequestModels_Products_Offers_OfferProductsPublicRequest } from '@monorepo-types/dc';
import { RequestModels_Products_Offers_V3_OfferProductsPublicV3Request } from '@monorepo-types/dc';
import { RequestModels_Products_Order_ProductOrder } from '@monorepo-types/dc';
import { RequestModels_Products_Solar_SolarLeadPublicRequest } from '@monorepo-types/dc';
import { ResponseDataDC_Products_Client_Models_DiscontinueReasons } from '@monorepo-types/dc';
import { ResponseDataSystem_String } from '@monorepo-types/dc';
import { ResponseModels_Products_Calculator_CalculatorResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Products_External_ExternalHeatpumpProductModel } from '@monorepo-types/dc';
import { ResponseModels_Products_External_ExternalHeatpumpProductResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Products_Interruption_InterruptionModel } from '@monorepo-types/dc';
import { ResponseModels_Products_Leads_LeadAppointmentCalendarResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Products_Leads_LeadsResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Products_Offers_PublicOffer } from '@monorepo-types/dc';
import { ResponseModels_Products_Offers_V3_OfferResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Products_Orders_CreateOrderResponseLocal } from '@monorepo-types/dc';
import { ResponseModels_Products_ProductCombinationResponseModel } from '@monorepo-types/dc';

import { request } from '../client';
import type { ApiRequestConfig } from '../client/types';

type Calculator = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  postalCode: string;
  houseNumber: number;
  requestBody: RequestModels_Products_Calculator_CalculatorRequestModel;
  houseNumberSuffix?: string;
};
/**
 * Calculator
 * Product Calculator (Omschakelaar / Solar calculator 2.0).
Anonymous operation.
 * @returns ResponseModels_Products_Calculator_CalculatorResponseModel Success
 */
export function calculator(
  { businessUnit, label, postalCode, houseNumber, requestBody, houseNumberSuffix }: Calculator,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Products_Calculator_CalculatorResponseModel> {
  return request(
    {
      method: 'POST',
      path: `/dxpweb/public/${businessUnit}/${label}/products/calculator`,
      query: { postalCode, houseNumber, houseNumberSuffix },
      body: requestBody,
      errors: { 400: 'Bad Request', 404: 'Not Found' },
    },
    requestConfig,
  );
}

type GetOfferProducts = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody: RequestModels_Products_Offers_OfferProductsPublicRequest;
};
/**
 * GetOfferProducts
 * Get OfferProducts public
 * @returns ResponseModels_Products_Offers_PublicOffer Success
 */
export function getOfferProducts(
  { businessUnit, label, requestBody }: GetOfferProducts,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Products_Offers_PublicOffer> {
  return request(
    {
      method: 'POST',
      path: `/dxpweb/public/${businessUnit}/${label}/products/offerproducts`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetOfferProductsV3 = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody: RequestModels_Products_Offers_V3_OfferProductsPublicV3Request;
};
/**
 * GetOfferProductsV3
 * Get OfferProducts public
 * @returns ResponseModels_Products_Offers_V3_OfferResponseModel Success
 */
export function getOfferProductsV3(
  { businessUnit, label, requestBody }: GetOfferProductsV3,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Products_Offers_V3_OfferResponseModel> {
  return request(
    {
      method: 'POST',
      path: `/dxpweb/public/v3/${businessUnit}/${label}/products/offerproducts`,
      body: requestBody,
      errors: { 400: 'Bad Request', 404: 'Not Found' },
    },
    requestConfig,
  );
}

type GetProductTypeCombinations = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody: RequestModels_Products_Offers_OfferProductsPublicRequest;
};
/**
 * GetProductTypeCombinations
 * Get ProductType Combinations
 * @returns ResponseModels_Products_ProductCombinationResponseModel Success
 */
export function getProductTypeCombinations(
  { businessUnit, label, requestBody }: GetProductTypeCombinations,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Products_ProductCombinationResponseModel> {
  return request(
    {
      method: 'POST',
      path: `/dxpweb/public/${businessUnit}/${label}/products/producttypecombinations`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type PutProductsOrder = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody: RequestModels_Products_Order_ProductOrder;
};
/**
 * PutProductsOrder
 * Places an order to the CRM Campaign Interface.
 * @returns ResponseDataSystem_String The order has been placed for queue and is being processed
 */
export function putProductsOrder(
  { businessUnit, label, requestBody }: PutProductsOrder,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseDataSystem_String> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/public/${businessUnit}/${label}/products/orders`,
      body: requestBody,
      errors: { 400: 'Means that the back-end or any related services had an error' },
    },
    requestConfig,
  );
}

type PutProductsOrderV2 = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody: RequestModels_Products_Order_ProductOrder;
};
/**
 * PutProductsOrderV2
 * Places an order to the CRM Campaign Interface.
 * @returns ResponseModels_Products_Orders_CreateOrderResponseLocal The order has been placed for queue and is being processed
 */
export function putProductsOrderV2(
  { businessUnit, label, requestBody }: PutProductsOrderV2,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Products_Orders_CreateOrderResponseLocal> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/public/v2/${businessUnit}/${label}/products/orders`,
      body: requestBody,
      errors: { 400: 'Means that the back-end or any related services had an error' },
    },
    requestConfig,
  );
}

type SubmitLeadPublic = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody: RequestModels_Products_Solar_SolarLeadPublicRequest;
};
/**
 * SubmitLeadPublic
 * Submits a solar lead.
 * @returns ResponseModels_Products_Leads_LeadsResponseModel Success
 */
export function submitLeadPublic(
  { businessUnit, label, requestBody }: SubmitLeadPublic,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Products_Leads_LeadsResponseModel> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/public/${businessUnit}/${label}/products/solarleads`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetLeadAppointments = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
};
/**
 * GetLeadAppointments
 * Retrieves all appointment dates in a range from now with a maximum of 3 weeks in the future
 * @returns ResponseModels_Products_Leads_LeadAppointmentCalendarResponseModel Returns all appointments
 */
export function getLeadAppointments(
  { businessUnit, label }: GetLeadAppointments,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Products_Leads_LeadAppointmentCalendarResponseModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/public/${businessUnit}/${label}/products/appointments/leads`,
      errors: { 400: 'Means that the back-end or any related services had an error' },
    },
    requestConfig,
  );
}

type CreateLeadAppointment = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody?: RequestModels_Products_Leads_CreateLeadAppointment;
};
/**
 * CreateLeadAppointment
 * Create an appointment
 * @returns ResponseModels_Products_Leads_LeadsResponseModel The appointment has been created
 */
export function createLeadAppointment(
  { businessUnit, label, requestBody }: CreateLeadAppointment,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Products_Leads_LeadsResponseModel> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/public/${businessUnit}/${label}/products/appointments/lead`,
      body: requestBody,
      errors: { 400: 'Means that the back-end or any related services had an error' },
    },
    requestConfig,
  );
}

type GetExternalHeatpumpProducts = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
};
/**
 * GetExternalHeatpumpProducts
 * Gets the external heatpump products.
 * @returns ResponseModels_Products_External_ExternalHeatpumpProductResponseModel Success
 */
export function getExternalHeatpumpProducts(
  { businessUnit, label }: GetExternalHeatpumpProducts,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Products_External_ExternalHeatpumpProductResponseModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/public/${businessUnit}/${label}/products/heatpump`,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetExternalHeatpumpProductById = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  id: string;
};
/**
 * GetExternalHeatpumpProductById
 * Gets the external heatpump product by Id.
 * @returns ResponseModels_Products_External_ExternalHeatpumpProductModel Success
 */
export function getExternalHeatpumpProductById(
  { businessUnit, label, id }: GetExternalHeatpumpProductById,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Products_External_ExternalHeatpumpProductModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/public/${businessUnit}/${label}/products/heatpump/${id}`,
      errors: { 400: 'Bad Request', 404: 'Not Found' },
    },
    requestConfig,
  );
}

type SubmitBusinessLead = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody: RequestModels_Products_Leads_BusinessLeadRequestModel;
  recaptchaToken?: string;
};
/**
 * SubmitBusinessLead
 * Submits a business lead.
 * @returns any Success
 */
export function submitBusinessLead(
  { businessUnit, label, requestBody, recaptchaToken }: SubmitBusinessLead,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/public/${businessUnit}/${label}/products/businessleads`,
      body: requestBody,
      headers: { recaptchaToken },
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type SubmitCommercialOpportunity = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody: RequestModels_Products_Leads_CommercialOpportunityRequestModel;
};
/**
 * SubmitCommercialOpportunity
 * Submit commercial business opportunity quoation
 * @returns any Success
 */
export function submitCommercialOpportunity(
  { businessUnit, label, requestBody }: SubmitCommercialOpportunity,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/public/${businessUnit}/${label}/products/commercialopportunity`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetInterruptions = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  startDate?: string;
  endDate?: string;
};
/**
 * GetInterruptions
 * Get all the current known interruptions on the Eneco Network
 * @returns ResponseModels_Products_Interruption_InterruptionModel Success
 */
export function getInterruptions(
  { businessUnit, label, startDate, endDate }: GetInterruptions,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Products_Interruption_InterruptionModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/public/${businessUnit}/${label}/products/interruptions`,
      query: { startDate, endDate },
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetDiscontinueReasons = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  productType: Products_Relocations_DiscontinueProductType;
};
/**
 * GetDiscontinueReasons
 * Get all discontinue reasons
 * @returns ResponseDataDC_Products_Client_Models_DiscontinueReasons Success
 */
export function getDiscontinueReasons(
  { businessUnit, label, productType }: GetDiscontinueReasons,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseDataDC_Products_Client_Models_DiscontinueReasons> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/public/${businessUnit}/${label}/products/${productType}/discontinue`,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}
